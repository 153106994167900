import { Action } from "redux";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { useSelector } from "react-redux";
import createSagaMiddleware from "@redux-saga/core";
import { rootSaga } from "./rootSaga";
import { ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./auth/slice";
import productsReducer from "./products/slice";
import notificationsReducer from "./notifications/slice";

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: {
      auth: authReducer,
      products: productsReducer,
      notifications: notificationsReducer,
    },
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({ thunk: false })
        .concat(sagaMiddleware)
        .concat(logger),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;

// DUCKS pattern
import {
  createAction,
  createSlice,
  nanoid,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState } from "..";
import {
  IPayloadUpdateProductFavourite,
  IProductDetail,
  IProductFeatured,
  IProductFeaturedRegion,
  IProductStore,
} from "../products/types";

const initialFeatured: IProductFeatured[] = [
  {
    id: 1,
    code: "DE-123",
    name: "Thịt trâu gác bếp",
    img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
    description:
      "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
    isFavourite: false,
  },
  {
    id: 2,
    code: "DE-232",
    name: "Lợn cắp nách Lai Châu - Lợn Lửng",
    img: "https://images.pexels.com/photos/3655916/pexels-photo-3655916.jpeg?auto=compress&cs=tinysrgb&w=1600",
    description:
      "Lợn cắp nách hay còn gọi là lợn lửng, món ăn đặc sản chỉ có ở Lai Châu mà bạn nên thử qua một lần. Nghe cái tên đã thấy thú vị rồi, hẳn là một món ăn rất đặc biệt ở vùng cao.Lợn cắp nách được nghe danh “ngon từ thịt, ngọt từ xương” nên dù chế biến món gì cũng rất hấp dẫn.",
    isFavourite: true,
  },
];

const initialFeaturedRegion: IProductFeaturedRegion[] = [
  {
    region: {
      id: 1,
      name: "Miền Bắc",
    },
    products: [
      {
        id: 1,
        code: "DE-123",
        name: "Thịt trâu gác bếp",
        img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
        description:
          "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
        isFavourite: false,
        sold: 830000,
        price: 100000,
        currencyIcon: "đ",
        unitSymbolIcon: "kg",
        unitSymbolPrice: 1,
      },
      {
        id: 2,
        code: "DE-123",
        name: "Thịt trâu gác bếp",
        img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
        description:
          "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
        isFavourite: false,
        sold: 830000,
        price: 100000,
        currencyIcon: "đ",
        unitSymbolIcon: "kg",
        unitSymbolPrice: 1,
      },
    ],
  },
  {
    region: {
      id: 1,
      name: "Miền Trung",
    },
    products: [
      {
        id: 1,
        code: "DE-123",
        name: "Thịt trâu gác bếp",
        img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
        description:
          "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
        isFavourite: false,
        sold: 830000,
        price: 100000,
        currencyIcon: "đ",
        unitSymbolIcon: "kg",
        unitSymbolPrice: 1,
      },
      {
        id: 2,
        code: "DE-123",
        name: "Thịt trâu gác bếp",
        img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
        description:
          "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
        isFavourite: false,
        sold: 830000,
        price: 100000,
        currencyIcon: "đ",
        unitSymbolIcon: "kg",
        unitSymbolPrice: 1,
      },
    ],
  },
  {
    region: {
      id: 1,
      name: "Miền Nam",
    },
    products: [
      {
        id: 1,
        code: "DE-123",
        name: "Thịt trâu gác bếp",
        img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
        description:
          "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
        isFavourite: false,
        sold: 830000,
        price: 100000,
        currencyIcon: "đ",
        unitSymbolIcon: "kg",
        unitSymbolPrice: 1,
      },
      {
        id: 2,
        code: "DE-123",
        name: "Thịt trâu gác bếp",
        img: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1600",
        description:
          "Đặc trưng là một món ăn khô, đây chính là món mồi ngon mà người dân Hà Giang thường hay chế biến để nhâm nhi với chút rượu.",
        isFavourite: false,
        sold: 8300,
        price: 100000,
        currencyIcon: "đ",
        unitSymbolIcon: "kg",
        unitSymbolPrice: 1,
      },
    ],
  },
];

const initialState: IProductStore = {
  // detail
  detail: {},
  requestDetail: "idle",
  messageResponeDetail: "",

  // featured
  featured: initialFeatured,
  requestFeatured: "idle",
  messageResponeFeatured: "",

  // featured region
  featuredRegion: initialFeaturedRegion,
  requestFeaturedRegion: "idle",
  messageResponeFeaturedRegion: "",

  // update favourite
  requestUpdateFavourite: "idle",
  messageResponeUpdateFavourite: "",
};

// slice
export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // detail
    getDetailByIdRequest(state) {
      state.detail = {};
      state.requestDetail = "loading";
    },
    getDetailByIdSucceeded(state, action: PayloadAction<IProductDetail>) {
      state.detail = action.payload;
      state.requestDetail = "success";
    },
    getDetailByIdFailured(state, action: PayloadAction<any>) {
      state.requestDetail = "failure";
      state.messageResponeDetail = action.payload.messageRespone;
    },

    // featured
    getFeaturedRequest(state) {
      state.requestFeatured = "loading";
      // state.featured = [];
    },
    getFeaturedSucceeded(state, action: PayloadAction<IProductFeatured[]>) {
      state.requestFeatured = "success";
      state.featured = action.payload;
    },
    getFeaturedFailured(state, action: PayloadAction<any>) {
      state.requestFeatured = "failure";
      state.messageResponeFeatured = action.payload.messageRespone;
    },

    // featured region
    getFeaturedRegionByIdRequest(state) {
      state.requestFeaturedRegion = "loading";
      // state.featuredRegion = [];
    },
    getFeaturedRegionByIdSucceeded(
      state,
      action: PayloadAction<IProductFeaturedRegion[]>
    ) {
      state.requestFeaturedRegion = "success";
      state.featuredRegion = action.payload;
    },
    getFeaturedRegionByIdFailured(state, action: PayloadAction<any>) {
      state.requestFeaturedRegion = "failure";
      state.messageResponeFeaturedRegion = action.payload.messageRespone;
    },

    // update favourite
    updateFavouriteRequest(state) {
      state.requestUpdateFavourite = "loading";
    },
    updateFavouriteSucceeded(state, action: PayloadAction<any>) {
      state.requestUpdateFavourite = "success";
      state.messageResponeUpdateFavourite = action.payload.messageRespone;
    },
    updateFavouriteFailured(state, action: PayloadAction<any>) {
      state.requestUpdateFavourite = "failure";
      state.messageResponeUpdateFavourite = action.payload.messageRespone;
    },
  },
});

// Actions
export const productsActions = {
  // Product
  getDetailById: createAction(`${productsSlice.name}/detail`, (id: number) => ({
    payload: id,
  })),
  getDetailByIdRequest: productsSlice.actions.getDetailByIdRequest,
  getDetailByIdSucceeded: productsSlice.actions.getDetailByIdSucceeded,
  getDetailByIdFailured: productsSlice.actions.getDetailByIdFailured,

  // Product Featured
  getFeatured: createAction(`${productsSlice.name}/featured`),
  getFeaturedRequest: productsSlice.actions.getFeaturedRequest,
  getFeaturedSucceeded: productsSlice.actions.getFeaturedSucceeded,
  getFeaturedFailured: productsSlice.actions.getFeaturedFailured,

  // Product Featured Region
  getFeaturedRegionById: createAction(
    `${productsSlice.name}/featuredRegionById`,
    (id: number) => ({
      payload: id,
    })
  ),
  getFeaturedRegionByIdRequest:
    productsSlice.actions.getFeaturedRegionByIdRequest,
  getFeaturedRegionByIdSucceeded:
    productsSlice.actions.getFeaturedRegionByIdSucceeded,
  getFeaturedRegionByIdFailured:
    productsSlice.actions.getFeaturedRegionByIdFailured,

  // Product Favourite
  updateFavourite: createAction<IPayloadUpdateProductFavourite>(
    `${productsSlice.name}/updateFavourite`
  ),
  updateFavouriteRequest: productsSlice.actions.updateFavouriteRequest,
  updateFavouriteSucceeded: productsSlice.actions.updateFavouriteSucceeded,
  updateFavouriteFailured: productsSlice.actions.updateFavouriteFailured,

  //
  delete: createAction<IProductDetail>(`${productsSlice.name}/delete`),
  fetchAll: createAction(`${productsSlice.name}/fetchAll`),
  update: createAction<IProductDetail>(`${productsSlice.name}/update`),
};

// Selectors
export const selectProductStore = (state: RootState) => state.products;

// Reducer
export default productsSlice.reducer;

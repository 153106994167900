import { lazy } from "react";
import { Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import AppHeader from "../components/header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/router/PrivateRoute";
import layout from "../components/layout";
import AppFooter from "../components/footer";

const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const Profile = lazy(() => import("../pages/profile/index"));
const Home = lazy(() => import("../pages/home/index"));
const ProductDetail = lazy(() => import("../pages/productDetail"));
const Classification = lazy(() => import("../pages/classification"));
const Search = lazy(() => import("../pages/search"));
const Cart = lazy(() => import("../pages/cart"));
const Pay = lazy(() => import("../pages/pay"));

const LayoutUser = () => {
  return (
    <Layout className="space-y-4">
      <Header>
        <AppHeader />
      </Header>
      <Content>
        <BrowserRouter>
          <Routes>
            {/* <PrivateRoute
          path="/admin"
          component={AdminPage}
          requiredRole="admin"
        /> */}
            <Route
              path="/profile"
              element={
                <PrivateRoute role="USER">
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route path={"/"} element={<Home />} />
            <Route path={"/dang-nhap"} element={<Login />} />
            <Route path={"/dang-ky"} element={<Register />} />

            <Route path={"/tim-kiem"} element={<Search />} />

            <Route path={"/classification"} element={<Classification />} />
            <Route path={"/cart"} element={<Cart />} />
            <Route path={"/pay"} element={<Pay />} />
            <Route path={"/san-pham/*"} element={<ProductDetail />} />
            {/* <Route path='*' element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </Content>
      {layout.showFooter(window.location.pathname) && (
        <Footer>
          <AppFooter />
        </Footer>
      )}
    </Layout>
  );
};

export default LayoutUser;

import { useState, lazy } from "react";
import { Layout, Menu, MenuProps } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { BrowserRouter, Navigate, Route, Routes, Link } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { TruckIcon } from "@heroicons/react/24/outline";

const Profile = lazy(() => import("../pages/profile/index"));
const Products = lazy(() => import("../pages/admin/products/index"));

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "/admin", <TruckIcon className="h-6 w-6 " />),
  getItem("Sản phẩm", "/admin/products", <TruckIcon className="h-6 w-6 " />),
  getItem("Đơn hàng", "/admin/orders", <TruckIcon className="h-6 w-6 " />),
  getItem("Nhân sự", "/admin/users", <TruckIcon className="h-6 w-6 " />),
  getItem("Khách hàng", "/admin/customers", <TruckIcon className="h-6 w-6 " />),
  getItem("Đối tác", "/admin/partners", <TruckIcon className="h-6 w-6 " />),
  getItem("Hồ sơ", "/admin/profile", <TruckIcon className="h-6 w-6" />),
  getItem("Cài đặt", "/admin/settings", <TruckIcon className="h-6 w-6" />),
  getItem("Báo cáo", "/admin/reports", <TruckIcon className="h-6 w-6" />),
  // getItem("Sản phẩm ", "sub1", <TruckIcon className="h-6 w-6" />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
];

const LayoutAdmin = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="flex justify-center">
          <img
            src={require("../../assets/img/logo.png")}
            alt="logo"
            className="h-11 m-3"
          />
        </div>
        <Menu
          theme="light"
          defaultSelectedKeys={[window.location.pathname]}
          mode="inline"
          items={items}
          onClick={(e) => (window.location.pathname = e.key)}
        />
      </Sider>
      <Layout>
        <Header>Header</Header>
        <Content className="table p-4">
          <BrowserRouter>
            <Routes>
              <Route path="/admin" element={<Profile />} />
              <Route path="/admin/products" element={<Products />} />
              <Route path="*" element={<Navigate to="/admin" replace />} />
            </Routes>
          </BrowserRouter>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutAdmin;

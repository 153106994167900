const SplashScreen = () => {
  return (
    <div className="bg-primary-400 h-screen w-screen flex flex-col items-center justify-center">
      <img
        src={
          "https://images.pexels.com/photos/376464/pexels-photo-376464.jpeg?auto=compress&cs=tinysrgb&w=1600"
        }
        className="animate-spin h-32 w-32 mb-4"
        alt="splashScreen"
      />
      <h1 className="text-white font-bold text-xl">Loading...</h1>
    </div>
  );
};

export default SplashScreen;

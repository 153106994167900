import clsx from "clsx";
import { ReactNode } from "react";

interface ILayoutContainer {
  children: ReactNode;
  className?: string;
}

const LayoutContainer: React.FC<ILayoutContainer> = (props) => {
  return (
    <div className={clsx("container", props.className)}>{props.children}</div>
  );
};

export default LayoutContainer;

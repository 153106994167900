import { ROUTE_PAGE } from "../enums/RoutePage";

interface IsSignPageProps {
  pathname: string;
}

export const isSignPage = ({ pathname }: IsSignPageProps) => {
  if (pathname === ROUTE_PAGE.SIGNIN || pathname === ROUTE_PAGE.SIGNUP) {
    return true;
  }
  return false;
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Image } from "antd";
import "./Logo.css";

const Logo: React.FC<{}> = () => {
  const { Link, Text } = Typography;
  const { t } = useTranslation();
  return (
    <>
      <Link href="/">
        <Image
          src={require("../../../assets/img/logo.png")}
          preview={false}
          height={"3.5rem"}
        />
      </Link>
      <Text className="text-sm font-bold text-primary-800 mt-[0.375rem] whitespace-nowrap">
        {t("CORE_VALUES")}
      </Text>
    </>
  );
};

export default Logo;

// DUCKS pattern
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IAuthStore, IUser } from "./types";

const initialState: IAuthStore = {};

// slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Signup
    signupSucceeded(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
  },
});

export const selectUser = (state: RootState) => state.auth.user;

// Reducer
export default authSlice.reducer;

import Link from "antd/es/typography/Link";
import * as strapi from "../../../helper/func/strapi";
import { useGetFooter } from "../../../store/layout/hook";
import LayoutContainer from "../layout/LayoutContainer";

const AppFooter: React.FC<{}> = (props) => {
  const { data, isLoading } = useGetFooter();

  return (
    <LayoutContainer className="flex flex-row text-white">
      <div className="basis-2/5">
        <img
          className="w-24 h-24 rounded-full mb-6"
          src={strapi.getAsset(data?.data.attributes.logo.data.attributes.url)}
        />
        <div className="space-y-1">
          <div>
            <span className="font-semibold">{`Địa chỉ: `}</span>
            {data?.data.attributes.address}
          </div>
          <div>
            {" "}
            <span className="font-semibold">{`Hotline: `}</span>
            {data?.data.attributes.hotline}
          </div>
          <div>
            <span className="font-semibold">{`Email: `}</span>
            {data?.data.attributes.email}
          </div>
        </div>
      </div>
      <div className="basis-3/5 flex flex-row mt-8">
        <div className="basis-1/3 space-y-2">
          <div className="font-semibold">Về DoEat</div>
          {data?.data.attributes.about?.data.map((_, i) => {
            return (
              <div>
                <Link
                  href={_.attributes.route}
                  key={`footer-about-${i}`}
                  className="!text-white"
                >
                  {_.attributes.name}
                </Link>
              </div>
            );
          })}
        </div>
        <div className="basis-1/3 space-y-2">
          <div className="font-semibold">Điều khoản chính sách</div>
          {data?.data.attributes.policy?.data.map((_, i) => {
            return (
              <div>
                <Link
                  href={_.attributes.route}
                  key={`footer-about-${i}`}
                  className="!text-white"
                >
                  {_.attributes.name}
                </Link>
              </div>
            );
          })}
        </div>
        <div className="basis-1/3 space-y-2">
          <div className="font-semibold">Sản phẩm tiêu biểu</div>
          {data?.data.attributes.featured?.data.map((_, i) => {
            return (
              <div>
                <Link
                  href={_.attributes.route}
                  key={`footer-about-${i}`}
                  className="!text-white"
                >
                  {_.attributes.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </LayoutContainer>
  );
};

export default AppFooter;

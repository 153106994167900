import { Navigate } from "react-router-dom";
import { useAuthStore } from "../../../store/auth/hook";
import Cookies from "js-cookie";

interface IPrivateRoute {
  role: string;
  children: React.ReactElement;
}
const PrivateRoute = ({ role, children }: IPrivateRoute) => {
  const user = JSON.parse(Cookies.get("doeat.com")!)?.user;
  const hasPermission = user?.roles?.includes(role);
  return hasPermission ? <>{children}</> : <Navigate to="/" />;
};

export default PrivateRoute;

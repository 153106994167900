import Cookies from "js-cookie";

export const getUser = () => {
  return JSON.parse(Cookies.get("doeat.com") ?? "{}")?.user;
};

export const getToken = () => {
  return JSON.parse(Cookies.get("doeat.com") ?? "{}")?.token;
};

export const getRefreshToken = () => {
  return JSON.parse(Cookies.get("doeat.com") ?? "{}")?.refreshToken;
};

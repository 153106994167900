import * as api from "./api";
import { actions } from "./actions";
import { useQuery } from "react-query";

export const useGetFooter = () => {
  return useQuery({
    queryKey: actions.GET_FOOTER,
    queryFn: api.getFooter,
  });
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getUser } from "./helper/func/cookies";
import LayoutUser from "./containers/layout/LayoutUser";
import LayoutAdmin from "./containers/layout/LayoutAdmin";

const App: React.FC<{}> = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    if (
      localStorage.getItem("i18next-lng") === "" ||
      localStorage.getItem("i18next-lng") === undefined ||
      localStorage.getItem("i18next-lng") === null
    ) {
      i18n.changeLanguage(lng);
      localStorage.setItem("i18next-lng", lng);
    } else {
      i18n.changeLanguage(`${localStorage.getItem("i18next-lng")}`);
    }
  }, []);

  const user = getUser();

  if (user?.roles.includes("ADMIN")) {
    return <LayoutAdmin />;
  } else {
    return <LayoutUser />;
  }
};

export default App;

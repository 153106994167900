import { MenuProps, Dropdown, Image } from "antd";
import "./ChangeLanguage.css";
import { useState } from "react";
import i18n from "../../i18n";

interface IFlagImages {
  [key: string]: string;
}

const ChangeLanguage: React.FC<{}> = () => {
  const [lng, setLng] = useState<string>(localStorage.getItem("i18next-lng")!);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18next-lng", lng);
    setLng(lng);
  };

  const flagImages: IFlagImages = {
    vi: require("../../assets/img/vi-icon.png"),
    en: require("../../assets/img/en-icon.png"),
    zh: require("../../assets/img/zh-icon.png"),
  };

  const items = [
    {
      label: <Image className="h-5 w-5" src={flagImages.vi} preview={false} />,
      key: "vi",
    },
    {
      label: <Image className="h-5 w-5" src={flagImages.en} preview={false} />,
      key: "en",
    },
    {
      label: <Image className="h-5 w-5" src={flagImages.zh} preview={false} />,
      key: "zh",
    },
  ];

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <div className="px-1 pt-2 pb-1 bg-white rounded-xl shadow-xl mt-2">
          {items.map((_) => (
            <div>
              <button type="button" onClick={() => changeLanguage(_?.key)}>
                {_?.label}
              </button>
            </div>
          ))}
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Image className="w-5 h-5" src={flagImages[lng]} preview={false} />
      </a>
    </Dropdown>
  );
};

export default ChangeLanguage;

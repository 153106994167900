import React from "react";
import Logo from "./Logo";
import ManagerAccount from "./ManagerAccount";
import SearchInput from "./SearchInput";
import { isSignPage } from "../../../helper/func/pathname";
import LayoutContainer from "../layout/LayoutContainer";

const AppHeader: React.FC<{}> = (props: any) => {
  const pathname = window.location.pathname;

  return (
    <LayoutContainer className="layout-header">
      <div className="grid justify-items-center">
        <Logo />
      </div>
      {isSignPage({ pathname }) ? (
        <></>
      ) : (
        <div>
          <SearchInput />
        </div>
      )}
      <ManagerAccount />
    </LayoutContainer>
  );
};

export default AppHeader;

const layout = {
  showFooter: (pathname: string) => {
    if (pathname?.includes("dang-ky") || pathname?.includes("dang-nhap")) {
      return false;
    }
    return true;
  },
};

export default layout;

import { AxiosError } from "axios";
import { notification } from "antd";
import { IResponseError } from "../notifications/types";
import { getToken } from "../../helper/func/cookies";

export default function setupAxios(axios: any, store: any) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
  axios.defaults.cdnURL = process.env.REACT_APP_BASE_CDN_URL;

  axios.interceptors.request.use(
    (config: any) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: any) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: any) => {
      // Thực hiện xử lý response trả về
      return response;
    },
    (error: AxiosError<IResponseError>) => {
      // Xử lý lỗi response
      if (error.response) {
        // Xử lý các mã lỗi HTTP 4xx và 5xx
        notification.error({
          message: error.response.data.error.name,
          description: error.response.data.error.message,
        });
      } else if (error.request) {
        // Xử lý lỗi không có response từ server
        notification.error({
          message: "Lỗi phải hồi",
          description: "Không nhận được phản hồi từ máy chủ. Vui lòng thử lại",
        });
      } else {
        // Xử lý các lỗi khác
        notification.error({
          message: "Lỗi hệ thống",
          description: "Có lỗi xảy ra. Vui lòng thử lại",
        });
      }
      return Promise.reject(error);
    }
  );
}

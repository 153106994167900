import "./i18n";
import "./index.css";
import React from "react";
import App from "./App";
import store from "./store";
import axios from "axios";
import ReactDOM from "react-dom/client";
import setupAxios from "./store/_helper/setupAxios";
import SplashScreen from "./containers/components/splash-screen";
import reportWebVitals from "./reportWebVitals";
import AntdConfigTheme from "./antd.config";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

setupAxios(axios, store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider {...AntdConfigTheme}>
        <QueryClientProvider client={queryClient}>
          <React.Suspense fallback={<SplashScreen />}>
            <App />
          </React.Suspense>
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Tooltip, Button, Typography } from "antd";
import "./SearchInput.css";

interface IOptionFilter {
  label: string;
  link: string;
}

const SearchInput: React.FC<{}> = (props: any) => {
  const { Text, Link } = Typography;
  const { t } = useTranslation();
  const [optionsFilter, setOptionsFilter] = useState<IOptionFilter[]>([
    {
      label: "Tất cả",
      link: "/tim-kiem",
    },
    {
      label: "Tỉnh thành",
      link: "/classification",
    },
    {
      label: "Hạng mục",
      link: "/classification",
    },
    {
      label: "Yêu thích",
      link: "/classification",
    },
    {
      label: "Giảm giá",
      link: "/classification",
    },
  ]);

  return (
    <>
      <Input
        placeholder={`${t("heading.search.placeholder")}`}
        className="text-sm font-medium text-gray-900 rounded-2xl items-center border-primary-800 pl-4 py-1 pr-1 w-[27.75rem]"
        suffix={
          <Tooltip title={t("search")}>
            <Button
              icon={
                <MagnifyingGlassIcon className="w-3.5 h-3.5 text-white ml-2 mr-2.5 my-1" />
              }
              className="w-fit h-fit rounded-r-[0.75rem] rounded-l-none bg-primary-800"
            />
          </Tooltip>
        }
      />

      <div className="ml-1 whitespace-nowrap">
        <div className="flex space-x-2">
          {optionsFilter?.map((_) => (
            <Link href={_?.link} className="text-sm font-normal">
              {_?.label}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchInput;

import React from "react";
import { useTranslation } from "react-i18next";
import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import { Typography, Divider, Tooltip, Badge } from "antd";
import ChangeLanguage from "../ChangeLanguage";
import "./ManagerAccount.css";
import { isSignPage } from "../../../helper/func/pathname";
import * as strapi from "../../../helper/func/strapi";
import Cookies from "js-cookie";

const ManagerAccount: React.FC<{}> = (props: any) => {
  const pathname = window.location.pathname;
  const { Text, Link } = Typography;
  const { t } = useTranslation();
  const user = JSON.parse(Cookies.get("doeat.com") ?? "{}")?.user;

  console.log("user: ", user);
  return (
    <div className="flex items-center">
      {isSignPage({ pathname }) ? (
        <>
          <Link className="text-sm font-normal" href="/">
            Trang chủ
          </Link>
        </>
      ) : user?.id ? (
        <Tooltip title={t("tooltip.profile")} arrow={false} zIndex={100}>
          <Link href="/profile" className="flex items-center !text-gray-900">
            <img
              className="w-5 h-5 rounded-full"
              src={strapi.getAsset(user?.avatar?.url)}
            />
            <div className="text-sm ml-1">{user?.name}</div>
          </Link>
        </Tooltip>
      ) : (
        <>
          <a className="text-sm font-normal" href="/dang-nhap">
            {t("login")}
          </a>
          <Divider type="vertical" className="h-6 border-black border-1" />
          <a href="/dang-ky" className="text-sm font-normal">
            {t("register")}
          </a>
        </>
      )}
      <div className="ml-10">
        {isSignPage({ pathname }) ? (
          <Text className="text-sm font-normal text-gray-400">Tiếng Việt</Text>
        ) : (
          <Tooltip title={t("shoppingCart")} arrow={false} zIndex={100}>
            <Link href="/cart" className="flex">
              <Badge count={user?.totalCart} overflowCount={99} size="small">
                <ShoppingCartIcon className="h-4" />
              </Badge>
            </Link>
          </Tooltip>
        )}
      </div>
      <div className="ml-5">
        <ChangeLanguage />
      </div>
    </div>
  );
};

export default ManagerAccount;

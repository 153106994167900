// DUCKS pattern
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";
import { IPayloadNotification, INotificationStore } from "./types";

const initialState: INotificationStore = {
  notification: {
    type: "",
    message: "",
    description: "",
  },
};

// slice
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    create(state, action: PayloadAction<IPayloadNotification>) {
      state.notification = action.payload;
    },
  },
});

// Selectors
export const selectNotificationStore = (state: RootState) =>
  state.notifications;

// Reducer
export default notificationsSlice.reducer;

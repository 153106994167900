module.exports = {
  theme: {
    token: {
      colorPrimary: "#588D3F",
      colorBorder: "#588D3F",
      colorPrimaryBorder: "#588D3F",
      colorLink: "#588D3F",
      colorLinkHover: "#9DCE73",
      colorLinkActive: "#588D3F",
      fontSize: 16,
      fontFamily: "Inter var, sans-serif",
      controlHeightLG: 44,
      controlHeight: 35,
    },
  },
};
